import MUITableLicensed from "@/components/MUITableLicensed/MUITableLicensed";
import { Patient } from "@/interfaces/IBooking";
import { IPatientAppointment } from "@/interfaces/IPatientAppointment";
import { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";
import { IAppointmentForm } from "./PatientDetailForm";

type TPatientsTableProps = {
  patients: IAppointmentForm[];
};


const columns: GridColDef<IAppointmentForm>[] = [
  {
    field: "fullName",
    headerName: "Member Name",
    type: "string",
    flex: 1,
  },
  {
    field: "dateOfBirth",
    headerName: "Date Of Birth",
    type: "string",
    flex: 1,
    // renderCell(params) {
    //   return (
    //     <Fragment>
    //       <a href={`mailto:${params.row.pharmacyLoginId}`}>
    //         {params.row.pharmacyLoginId}
    //       </a>
    //     </Fragment>
    //   );
    // },
  },
  {
    field: "gender",
    headerName: "Gender",
    type: "string",
    flex: 1,
    // renderCell(params) {
    //   return (
    //     <Fragment>
    //       <a href={`tel:${params.row.phoneNumber}`}>{params.row.phoneNumber}</a>
    //     </Fragment>
    //   );
    // },
  },
  {
    field: "relationShips",
    headerName: "Relationship",
    headerAlign: "center",
    align: "center",
    flex: 0.5,
    // renderCell(params) {
    //   return (
    //     <Fragment>
    //       {params.row.isAdministrator ? (
    //         <CheckCircleOutline
    //           color="success"
    //           sx={{
    //             width: 24,
    //             height: 24,
    //           }}
    //         />
    //       ) : (
    //         <RemoveCircleOutline
    //           color="error"
    //           sx={{
    //             width: 24,
    //             height: 24,
    //           }}
    //         />
    //       )}
    //     </Fragment>
    //   );
    // },
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 0.75,
    // renderCell(params) {
    //   return <ActionButtonCell {...params} />;
    // },
  },
];

const PatientsTable = ({ patients }: TPatientsTableProps) => {
  return (
    <div>
      <MUITableLicensed columns={columns} rows={patients} />
    </div>
  );
};

export default PatientsTable;
